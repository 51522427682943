<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">{{ $t("message.add_order_payment") }}</div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button type="success" @click="quit(true)">{{
                $t("message.save_and_close")
              }}</el-button>
              <el-button @click="quit(true)">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="modal_blocks_cashier p-3">
      <el-row :gutter="20" class="mt-3">
        <el-col :sm="12">
          <el-form ref="form" class="from_x">
            <el-autocomplete
              :disabled="true"
              :placeholder="userNumber"
              class="w_100s"
              clearable
            ></el-autocomplete>
          </el-form>
        </el-col>
        <el-col :sm="12">
          <div
            class="align-items-center align-self-center text-right pr-0 mb-2"
            v-loading="loadingData"
          >
            <span class="align-items-center align-self-center pr-2">
              <el-tag
                >{{ $t("message.total_amount") }}:
                {{
                  model.patientBalance.total_amount
                    ? model.patientBalance.total_amount
                    : 0 | formatMoney
                }}
                {{ $t("message.c_sum") }}</el-tag
              >
            </span>
            <span class="align-items-center align-self-center pr-2">
              <el-tag type="success"
                >{{ $t("message.paid") }}:
                {{
                  model.patientBalance.paid_up
                    ? model.patientBalance.paid_up
                    : 0 | formatMoney
                }}
                {{ $t("message.c_sum") }}</el-tag
              >
            </span>
            <span class="align-items-center align-self-center pr-2">
              <el-tag type="warning"
                >{{ $t("message.not_paid") }}:
                {{
                  model.patientBalance.not_paid
                    ? model.patientBalance.not_paid
                    : 0 | formatMoney
                }}
                {{ $t("message.c_sum") }}</el-tag
              >
            </span>
          </div>
        </el-col>
      </el-row>

      <el-tabs type="border-card" class="mt-4" v-loading="loadingData">
        <el-tab-pane :label="$t('message.add_transaction')">
          <!-- <el-row>
            <el-button type="danger">Без расчета</el-button>
          </el-row> -->
          <el-form ref="form" size="small" :model="form">
            <el-row :gutter="20" class="mt-3">
              <el-col :span="5">
                <el-form-item :label="$t('message.payment_type')">
                  <crm-payment-types
                    v-model="form.payment_type_id"
                    style="width: 100%"
                    :placeholder="$t('message.payment_type')"
                  ></crm-payment-types>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item :label="$t('message.amount')">
                  <el-input
                    type="number"
                    v-model="form.amount"
                    :placeholder="$t('message.amount')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item :label="$t('message.date_0')">
                  <el-date-picker
                    type="date"
                    :placeholder="$t('message.date_0')"
                    v-model="form.date_time"
                    style="width: 100%"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item :label="$t('message.comment')">
                  <el-input
                    v-model="form.comment"
                    :placeholder="$t('message.comment')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-button
                  type="success"
                  :loading="loadingButton"
                  :disabled="loadingButton ? true : false"
                  @click="submit(true)"
                  style="width: 100%; margin-top: 19px; height: 32px"
                  >{{ $t("message.add_transaction") }}
                </el-button>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
        <el-tab-pane :label="$t('message.all_service')">
          <BalanceUslugaList
            :selected="model.id"
            :hospitalPatient="model.patient"
          />
        </el-tab-pane>
        <el-tab-pane :label="$t('message.history_transaction')">
          <BalanceList
            v-on:rollback="afterOpened"
            ref="payments"
            :selected="model.id"
          />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import BalanceUslugaList from "./components/balanceUslugaList";
import BalanceList from "./components/balanceList";
import drawerChild from "@/utils/mixins/drawer-child";
import { mapGetters, mapActions } from "vuex";
import { i18n } from "@/utils/i18n";
import CrmPaymentTypes from "../../../components/selects/crm-paymentTypes";

export default {
  mixins: [drawerChild],
  name: "clientBalance",
  props: ["selected"],
  components: {
    BalanceUslugaList,
    CrmPaymentTypes,
    BalanceList,
  },
  data() {
    return {
      userNumber: "",
      timeout: null,
      loadingButton: false,
      loadingData: false,
      form: {},
      form2: {},
    };
  },

  computed: {
    ...mapGetters({
      patients: "patientBalances/search_list",
      list: "patientBalances/list",
      columns: "patientBalances/columns",
      pagination: "patientBalances/pagination",
      filter: "patientBalances/filter",
      sort: "patientBalances/sort",
      corpses: "patientBalances/list",
      model: "patientBalances/model",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  mounted() {
    this.fetchPatients = _.debounce(this.loadFilteredPatients, 500);
  },
  methods: {
    ...mapActions({
      save: "patientTransactions/store",
      updateList: "patientBalances/index",
      updateSort: "patientBalances/updateSort",
      updateFilter: "patientBalances/updateFilter",
      updateColumn: "patientBalances/updateColumn",
      updatePatients: "patientBalances/search",
      updatePagination: "patientBalances/updatePagination",
      editModel: "patientBalances/show",
      empty: "patientBalances/empty",
      delete: "patientBalances/destroy",
      refreshData: "patientBalances/refreshData",
      fetchModel: "patientBalances/showModel",
    }),
    submit(close = true) {
      this.loadingButton = true;
      this.form.patient_history_id = this.model.id;
      this.save(this.form)
        .then((res) => {
          this.loadingButton = false;
          this.$notify({
            title: "Успешно",
            type: "success",
            offset: 130,
            message: res.message,
          });
          this.getModel(this.model);
          this.emptyModel();
          this.$refs.payments.fetchAgain();
        })
        .catch((err) => {
          this.loadingButton = false;
          this.$notify({
            title: "Ошибка",
            type: "error",
            offset: 130,
            message: err.error.message,
          });
        });
    },
    searchPatients() {
      this.fetchPatients();
    },
    getModel(model) {
      this.focus = false;
      this.loadingData = true;
      this.fetchModel({
        patient_hitory_id: model.id,
      })
        .then((res) => {
          this.loadingData = false;
        })
        .catch((err) => {
          this.loadingData = false;
          this.$notify({
            title: "Ошибка",
            type: "error",
            offset: 130,
            message: err.error.message,
          });
        });
    },
    getModelAfterSubmit(model) {
      this.loadingData = true;
      this.fetchModel({
        patient_hitory_id: model.link,
      })
        .then((res) => {
          this.loadingData = false;
        })
        .catch((err) => {
          this.loadingData = false;
          this.$notify({
            title: "Ошибка",
            type: "error",
            offset: 130,
            message: err.error.message,
          });
        });
    },
    loadFilteredPatients() {
      const query = { search: this.search };
      if (!this.loadingData) {
        this.loadingData = true;
        this.updatePatients(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    fetchData() {
      const query = { ...this.filter, ...this.pagination, ...this.sort };
      if (!this.loadingData) {
        this.loadingData = true;
        this.updateList(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },

    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch((err) => {});
    },
    afterOpened() {
      if (this.selected && !this.loadingData) {
        this.loadingData = true;
        this.userNumber = this.selected.number;
        this.fetchModel({
          patient_hitory_id: this.selected.id,
        })
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
            this.$notify({
              title: "Ошибка",
              type: "error",
              offset: 130,
              message: err.error.message,
            });
          });
      }
    },
    afterClosed() {},
	parent() {
		return this.$parent.$parent.$parent;
	},
    quit(close = true) {
      this.parent().listChanged();
      if (close == true) {
        this.close();
      }
    },
    async show(model) {
      await this.showModel(model.id)
        .then((res) => {
          this.drawerShow = true;
        })
        .catch((err) => {});
    },
    destroy(model) {
      this.delete(model.id)
        .then((res) => {
          this.$notify({
            title: "Успешно",
            type: "success",
            offset: 130,
            message: res.message,
          });
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    emptyModel() {
      this.form.amount = "";
      this.form.date_time = "";
      this.form.comment = "";
    },
    open() {
      this.$confirm(
        i18n.t("message.delete_notification"),
        i18n.t("message.warning"),
        {
          confirmButtonText: i18n.t("message.yes"),
          cancelButtonText: i18n.t("message.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          this.$message({
            type: "success",
            message: i18n.t("message.delete_success"),
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: i18n.t("message.delete_calcel"),
          });
        });
    },
  },
};
</script>
<style lang="scss">
.w_100s {
  width: 100%;
  input {
    width: 100%;
  }
}
</style>